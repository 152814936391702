<script setup lang="ts">
import {
  ref, computed, watch,
} from 'vue';

import useArticles, { Article } from './useArticles';
import FaqSearchForm from './FaqSearchForm.vue';
import FaqSearchResults from './FaqSearchResults.vue';

const emit = defineEmits<{(e: 'search-results', results: boolean | undefined, searched:boolean): void;
}>();

const query = ref<string>('');
const hasSearched = ref<boolean>(false);

const onSubmit = (value: string) => {
  query.value = value;
  hasSearched.value = true;
};

const { isLoading, data, error } = useArticles(
  computed(() => ({ query: query.value })),
  {
    enabled: computed(() => query.value.length > 2),
  },
);

const hasSearchResults = computed(
  () => data?.value?.data.articles && data?.value?.data.articles.length > 0,
);
const searchResults = computed(
  () => (hasSearchResults.value ? data?.value?.data.articles : [] as Article[]),
);

watch(searchResults, () => {
  emit('search-results', hasSearchResults.value, hasSearched.value);
});
</script>

<template>
  <div>
    <FaqSearchForm
      :on-submit="onSubmit"
      :initial-values="{ query: '' }"
      :loading="isLoading"
    />
    <div aria-live="polite" role="status">
      <div v-if="isLoading">
        <p>Loading...</p>
      </div>
      <div v-if="error">
        <p>Error: {{ error.message }}</p>
      </div>
      <div v-if="hasSearchResults" tabindex="0">
        <h3 class="text-xl text-left font-title font-semibold uppercase tracking-wide text-primary-darker mb-2 mt-16">
          {{ searchResults?.length }} results available for <span class="italic">{{ query }}</span>
        </h3>
        <h5 class="text-xs text-left text-gray-700">
          Frequently Asked Questions
        </h5>
        <FaqSearchResults :results="searchResults" />
      </div>
      <div v-if="!hasSearchResults && !isLoading && !error && hasSearched">
        <p>No results found.</p>
      </div>
    </div>
  </div>
</template>
