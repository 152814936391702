<template>
  <transition name="fade">
    <img
      v-if="showImage1 || !image2"
      :src="image1.src"
      :alt="image1.alt"
      :class="$props.imageClass"
    >
    <img
      v-else
      :src="image2.src"
      :alt="image2.alt"
      :class="$props.imageClass"
    >
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { object, string } from 'vue-types';

export interface ImagesProperty {
  src: string;
  alt: string;
}

// https://v3.vuejs.org/guide/transitions-enterleave.html#transition-modes
export default defineComponent({
  props: {
    image1: object<ImagesProperty>().isRequired,
    image2: object<ImagesProperty>(),
    showImage1: Boolean,
    imageClass: string().def(''),
  },
});
</script>

<style>
  .fade-enter-from {
    opacity: 0;
  }
  .fade-enter-to {
    opacity: 1;
  }
  .fade-enter-active {
    transition: all 2s ease;
  }
  .fade-leave-from {
    opacity: 1;
  }
  .fade-leave-to {
    opacity: 0;
  }
  .fade-leave-active {
    transition: all 2s ease;
  }
</style>
