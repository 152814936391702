<template>
  <div class="">
    <!-- Mobile menu button -->
    <button
      type="button"
      :class="[
        'inline-flex items-center justify-center p-2 rounded-md text-gray-600',
        'hover:text-gray-700 hover:bg-gray-200',
        'focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500',
      ]"
      aria-controls="mobile-menu"
      aria-expanded="false"
      @click.prevent="onShow"
    >
      <span class="sr-only">Open main menu</span>
      <!--
        Icon when menu is closed.

        Heroicon name: outline/menu

        Menu open: "hidden", Menu closed: "block"
        -->
      <svg
        class="block h-6 w-6 tracking-wide leading-relaxed"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
      <!--
        Icon when menu is open.
        Heroicon name: outline/x
        Menu open: "block", Menu closed: "hidden"
        -->
      <svg
        class="hidden h-6 w-6 tracking-wide leading-relaxed"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
    <modal
      :show="showModal"
      :close="onClose"
      :delete-class="true"
      class="flex justify-end h-screen"
      container-class=""
    >
      <div class="h-full w-full max-w-xs bg-white overflow-auto relative mb-10">
        <button
          v-if="onClose"
          :class="[
            'block absolute right-0 top-0 z-30 text-xl px-2 py-1',
            'text-gray-600 hover:text-gray-700',
          ]"
          aria-label="Close modal"
          type="button"
          @click="onClose"
        >
          <i class="fa fa-times" />
        </button>
        <MenuLinks
          class="mt-9"
          class-item="mobile-menu-item"
        />
        <div v-if="name" class="border-t mt-3 pt-3">
          <MenuProfileMobile
            class-item="mobile-menu-item"
            :account-url="accountUrl"
            :redirect-logout-url="redirectLogoutUrl"
          />
        </div>
        <div v-else class="border-t mt-3 pt-3">
          <a
            :href="accountUrl"
            :class="[
              'inline-flex ml-3 items-center px-2 py-1 border border-transparent',
              'text-base font-medium rounded-md shadow-sm text-white ',
              'bg-primary hover:bg-primary-lighter space-x-2',
              'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            ]"
            target="_blank"
            rel="noopener"
          >
            <span>Sign In</span>
            <i class=" text-primary-default fas fa-arrow-right" />
          </a>
        </div>
        <div class="border-t mt-3 pt-3">
          <div class="mx-2">
            <slot />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { string } from 'vue-types';

import modal from '../../../ui/modal/modal.vue';
import MenuLinks from './menu-links.vue';
import MenuProfileMobile from './menu-profile-mobile.vue';

export default defineComponent({
  name: 'ModalMenu',
  components: {
    modal,
    MenuLinks,
    MenuProfileMobile,
  },
  props: {
    name: string(),
    accountUrl: string().isRequired,
    redirectLogoutUrl: string().isRequired,
  },
  setup() {
    const showModal = ref(false);
    return {
      showModal,
    };
  },
  methods: {
    onClose() {
      this.showModal = false;
    },
    onShow() {
      this.showModal = true;
    },
  },
});
</script>
