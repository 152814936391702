<script setup lang="ts">
import {
  Carousel, Navigation, Slide, Pagination,
} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import { testimonies } from './testimonies';
import Testimony from './Testimony.vue';

// breakpoints are mobile first
// any settings not specified will fallback to the carousel settings
const breakpoints = {
  // 700px and up
  700: {
    itemsToShow: 2,
    snapAlign: 'center',
  },
  // 1024 and up
  1024: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
  // 1024 and up
  2048: {
    itemsToShow: 4,
    snapAlign: 'start',
  },
};

</script>

<template>
  <Carousel :breakpoints="breakpoints" :autoplay="5000" pause-autoplay-on-hover>
    <Slide v-for="testimony in testimonies" :key="testimony.reviewer">
      <div class="carousel__item h-full">
        <Testimony :testimony="testimony" class="h-full" />
      </div>
    </Slide>
    <template #addons>
      <Pagination />
      <Navigation />
    </template>
  </Carousel>
</template>

<style>
.carousel__item {
  width: 100%;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: transparent;
}
</style>
