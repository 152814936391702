<template>
  <ul :class="$props.class">
    <li>
      <a :class="classItem" href="/renters" aria-label="Menu link to learn more about GradGuard renters insurance">
        Renters
      </a>
    </li>
    <li>
      <a :class="classItem" href="/tuition" aria-label="Menu link to learn more about GradGuard tuition insurance">
        Tuition
      </a>
    </li>
    <li>
      <a :class="classItem" href="/support" aria-label="Menu link to GradGuard support page">
        Support
      </a>
    </li>
    <li>
      <a :class="classItem" href="https://hub.gradguard.com" target="_blank" rel="noopener" aria-label="Menu link to GradGuard school's hub (opens a new window)">
        For Schools
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { string } from 'vue-types';

export default defineComponent({
  props: {
    class: string(),
    classItem: string(),
  },
});
</script>
