<!-- eslint-disable vue/max-len -->
<template>
  <h2 class="px-5">
    View by State
  </h2>
  <AccordionLetter title="A" :states="aStates" />
  <AccordionLetter title="C" :states="cStates" />
  <AccordionLetter title="D" :states="dStates" />
  <AccordionLetter title="F" :states="fStates" />
  <AccordionLetter title="G" :states="gStates" />
  <AccordionLetter title="H" :states="hStates" />
  <AccordionLetter title="I" :states="iStates" />
  <AccordionLetter title="K" :states="kStates" />
  <AccordionLetter title="L" :states="lStates" />
  <AccordionLetter title="M" :states="mStates" />
  <AccordionLetter title="N" :states="nStates" />
  <AccordionLetter title="O" :states="oStates" />
  <AccordionLetter title="P" :states="pStates" />
  <AccordionLetter title="R" :states="rStates" />
  <AccordionLetter title="S" :states="sStates" />
  <AccordionLetter title="T" :states="tStates" />
  <AccordionLetter title="U" :states="uStates" />
  <AccordionLetter title="V" :states="vStates" />
  <AccordionLetter title="W" :states="wStates" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AccordionLetter from '@/components/licensing/accordion-letter.vue';

export default defineComponent({
  name: 'Licensing',
  components: {
    AccordionLetter,
  },
  data() {
    return {
      aStates: [
        {
          name: 'Alabama',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '415178',
        },
        {
          name: 'Alaska',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '77541',
        },
        {
          name: 'Arizona',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '935141',
        },
        {
          name: 'Arkansas',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '347880',
        },
      ],
      cStates: [
        {
          name: 'California',
          entity: 'Next Generation Insurance Group, DBA:NGI Insurance Services',
          licenseNumber: '0G44978',
        },
        {
          name: 'Colorado',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '328585',
        },
        {
          name: 'Connecticut',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '2323274',
        },
      ],
      dStates: [
        {
          name: 'Delaware',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '1088741',
        },
      ],
      fStates: [
        {
          name: 'Florida',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'L059685',
        },
      ],
      gStates: [
        {
          name: 'Georgia',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '127098',
        },
      ],
      hStates: [
        {
          name: 'Hawaii',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '364929',
        },
      ],
      iStates: [
        {
          name: 'Idaho',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '305848',
        },
        {
          name: 'Illinois',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'FEIN',
        },
        {
          name: 'Indiana',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '632049',
        },
        {
          name: 'Iowa',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '1002030654',
        },
      ],
      kStates: [
        {
          name: 'Kansas',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'FEIN-000',
        },
        {
          name: 'Kentucky',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'Life & Health 69852',
        },
      ],
      lStates: [
        {
          name: 'Louisiana',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '479289',
        },
      ],
      mStates: [
        {
          name: 'Maine',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'AGN162097',
        },
        {
          name: 'Maryland',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'NPF 100015074',
        },
        {
          name: 'Massachusetts',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '1861166',
        },
        {
          name: 'Michigan',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'FEIN',
        },
        {
          name: 'Minnesota',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '40148258',
        },
        {
          name: 'Mississippi',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '15012406',
        },
        {
          name: 'Missouri',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'AG8025427',
        },
        {
          name: 'Montana',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '710398',
        },
      ],
      nStates: [
        {
          name: 'Nebraska',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '377138',
        },
        {
          name: 'Nevada',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '635529',
        },
        {
          name: 'New Hampshire',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '2047216',
        },
        {
          name: 'New Jersey',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '1206009',
        },
        {
          name: 'New Mexico',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '100004670',
        },
        {
          name: 'North Carolina',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '1000040163',
        },
        {
          name: 'New York',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '1116028',
        },
      ],
      oStates: [
        {
          name: 'Ohio',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '39007',
        },
        {
          name: 'Oklahoma',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '10008481',
        },
        {
          name: 'Oregon',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '819907',
        },
      ],
      pStates: [
        {
          name: 'Pennsylvania',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '541260',
        },
      ],
      rStates: [
        {
          name: 'Rhode Island',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: 'RI does not issue a business entity/agency license. The licensed producer for Next Generation Insurance Group, LLC in RI is NGI\'s Managing Partner William A. Suneson - License Number 2068000',
        },
      ],
      sStates: [
        {
          name: 'South Carolina',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '173179',
        },
        {
          name: 'South Dakota',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '10007750',
        },
      ],
      tStates: [
        {
          name: 'Tennessee',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '6015',
        },
        {
          name: 'Texas',
          entity: 'Next Generation Insurance Group DBA: NGI Group, LLC',
          licenseNumber: '1552614',
        },
      ],
      uStates: [
        {
          name: 'Utah',
          entity: 'Next Generation Insurance Group DBA: NGI Group, LLC',
          licenseNumber: '307826',
        },
      ],
      vStates: [
        {
          name: 'Vermont',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '644106',
        },
        {
          name: 'Virginia',
          entity: 'Next Generation Insurance Group DBA: NGI Group, LLC',
          licenseNumber: '125703',
        },
      ],
      wStates: [
        {
          name: 'Washington',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '739059',
        },
        {
          name: 'Washington D.C.',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '2866010',
        },
        {
          name: 'West Virginia',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '6090',
        },
        {
          name: 'Wisconsin',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '2536852',
        },
        {
          name: 'Wyoming',
          entity: 'Next Generation Insurance Group, LLC',
          licenseNumber: '184184',
        },
      ],
    };
  },
});
</script>
