<template>
  <div>
    <Alert
      v-if="isEmailSent"
      type="primary"
      :hide-icon="true"
      class="mb-5"
    >
      Thank you for helping to spread the word about GradGuard!
    </Alert>
    <ReferralForm
      ref="form"
      :error="error"
      :loading="isLoading"
      :initial-values="initialValues"
      @submit="submit"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onUnmounted, ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { object } from 'vue-types';
import { useForm } from 'vee-validate';
import { usePost, ApiResponse } from 'use-api-vue';

import Alert from '../../ui/Alert/Alert.vue';
import ReferralForm from './ReferralForm.vue';
import { FormValues } from './ReferralFormValues';
import { ServerErrors, ServerResponse } from '@/plugin/ajax/ApiResponse';
import MessageProperties from '@/global/interface';

export default defineComponent({
  name: 'Referral',
  components: {
    ReferralForm,
    Alert,
  },
  props: {
    initialValues: object<FormValues>(),
  },
  setup() {
    const isEmailSent = ref(false);
    const post = usePost({ onCancelCallback: onUnmounted });
    const {
      isPending: isLoading, data, mutateAsync: send, error,
    } = useMutation<ApiResponse<ServerResponse<MessageProperties>>, ServerErrors, FormValues>({
      mutationFn: (params) => post('/api/referral', params),
    });

    return {
      isLoading,
      data,
      send,
      error,
      isEmailSent,
    };
  },

  methods: {
    resetForm() {
      const form = this.$refs.form as ReturnType<typeof useForm>;
      form.resetForm();
    },
    async submit(formValues: FormValues) {
      this.isEmailSent = false;
      const response = await this.send(formValues);
      if (response.data?.data.type === 'success') {
        this.isEmailSent = true;
        this.resetForm();
      }
    },
  },
});
</script>
