import { onUnmounted, reactive } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { useGet, ApiResponse } from 'use-api-vue';

import { AgencyHoursProperties } from './agencyHoursProps';
import { ServerResponse, ServerErrors } from '@/plugin/ajax/ApiResponse';
import { MaybeRef } from '@/plugin/interfaces';

interface AgencyHoursParams {
  date: MaybeRef<string>;
}
const useAgencyHours = (params: AgencyHoursParams) => {
  const get = useGet({ onCancelCallback: onUnmounted });

  return useQuery<ApiResponse<ServerResponse<AgencyHoursProperties>>, ServerErrors>({
    queryKey: reactive(['useAgencyHours', params]),
    queryFn: (context) => {
      const parameters = context.queryKey[1] as AgencyHoursParams;
      return get<ServerResponse<AgencyHoursProperties>>(`/api/agency-hours/${parameters.date}`);
    },
  });
};

export default useAgencyHours;
