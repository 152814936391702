<template>
  <div class="">
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          :class="[
            'flex items-center w-full px-3 border leading-5 rounded-md h-10',
            'space-x-2 text-primary-lighter border-primary-lighter',
            'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
            'sm:text-sm font-semibold',
          ]"
        >
          <span>
            {{ name }}
          </span>
          <i class="hidden fas fa-chevron-down" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          :class="[
            'absolute right-0 w-56 mt-2 origin-top-right bg-white',
            'divide-y divide-gray-100 rounded-md shadow-lg',
            'ring-1 ring-black ring-opacity-5 focus:outline-none',
          ]"
        >
          <div class="px-1 py-1">
            <MenuItem v-slot="{ active }">
              <a
                :href="accountUrl"
                :class="[
                  active ? 'bg-primary-darker text-white' : 'text-gray-900',
                  'group flex space-x-2 rounded-md items-center w-full px-2 py-2 text-sm',
                ]"
                target="_blank"
                rel="noopener"
              >
                <i class="fas fa-graduation-cap" />
                <span>
                  My Policies
                </span>
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                :href="`${accountUrl}/account`"
                :class="[
                  active ? 'bg-primary-darker text-white' : 'text-gray-900',
                  'group flex space-x-2 rounded-md items-center w-full px-2 py-2 text-sm',
                ]"
                target="_blank"
                rel="noopener"
              >
                <i class="fas fa-user-alt" />
                <span>
                  Settings
                </span>
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                :href="`${accountUrl}/web/session/logout?redirect=${redirectLogoutUrl}`"
                :class="[
                  active ? 'bg-primary-darker text-white' : 'text-gray-900',
                  'group flex space-x-2 rounded-md items-center w-full px-2 py-2 text-sm',
                ]"
              >
                <i class="fas fa-sign-out-alt" />
                <span>
                  Logout
                </span>
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { string } from 'vue-types';
import {
  Menu, MenuButton, MenuItems, MenuItem,
} from '@headlessui/vue';

export default defineComponent({
  name: 'ProfileMenu',
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  props: {
    name: string().isRequired,
    accountUrl: string().isRequired,
    redirectLogoutUrl: string().isRequired,
  },
});
</script>
