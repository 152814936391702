import {
  inject, provide, reactive, getCurrentInstance,
} from 'vue';

const USE_LOAD_IMAGE = 'USE_LOAD_IMAGE';

export const useLoadImageProvider = (): void => {
  provide(USE_LOAD_IMAGE, reactive<Record<string, boolean>>({}));
};

export const useLoadImage = () => {
  const vm = getCurrentInstance()?.proxy;

  if (!vm) {
    throw new Error(
      'vue-query hooks can only be used inside setup() function.',
    );
  }
  const client = inject<Record<string, boolean>>(USE_LOAD_IMAGE);
  if (!client) {
    throw new Error(
      `No client ${USE_LOAD_IMAGE} found in Vue context, use 'useLoadImageProvider' to set one in the root component.`,
    );
  }

  return client;
};
