<template>
  <div
    :class="$props.class"
    @mouseenter="pause"
    @mouseleave="play"
    @focus="play"
    @blur="pause"
  >
    <div
      v-for="(imgs, index) in imagesGrid"
      :key="index"
      class="flex items-center h-24 px-3"
      :class="backgrounColor"
    >
      <div class="relative w-full h-12 flex items-center justify-center">
        <TransitionImages
          :images="imgs"
          :base-url="baseUrl"
          :signal="signal"
        />
      </div>
    </div>
  </div>
  <div class="text-right mr-1">
    <button
      class="text-gray-500 px-1 hover:text-gray-700 focus:text-gray-700"
      :aria-label="isPaused ? 'Play colleges and universities animation' : 'Pause colleges and universities animation'"
      type="button"
      @click.prevent="() => isPaused ? play() : pause()"
    >
      <i v-if="isPaused" class="fas fa-play" aria-hidden="true" />
      <i v-else class="fas fa-pause" aria-hidden="true" />
    </button>
  </div>
</template>

<script setup lang="ts">
import {
  computed, ref, onMounted,
} from 'vue';
import { array, number, string } from 'vue-types';

import { ImagesProperty } from './functions';
import TransitionImages from './transition-images.vue';
import { useLoadImageProvider } from './useLoadImages';

useLoadImageProvider();

const getImageGroups = (images: ImagesProperty[], totalGroups: number) => {
  const arrayToSplice = [...images];
  const result: ImagesProperty[][] = [];
  let nTotalGroups = totalGroups;
  while (arrayToSplice.length) {
    result.push(
      arrayToSplice.splice(0, Math.ceil(arrayToSplice.length / nTotalGroups)),
    );
    nTotalGroups -= 1;
  }
  return result;
};

const props = defineProps({
  images: array<ImagesProperty>().isRequired,
  baseUrl: string().def(''),
  totalGroups: number().isRequired,
  interval: number().def(10000),
  bgColor: string().def('bg-gray-50'),
  class: string().def(''),
});

const imagesGrid = computed(() => getImageGroups(props.images, props.totalGroups));
const isPaused = ref(false);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timeout: any;
const backgrounColor = computed(() => props.bgColor || 'bg-gray-50');

const pause = () => {
  isPaused.value = true;
  if (timeout) {
    clearInterval(timeout);
  }
};

const signal = ref(false);
const updateSignal = () => {
  signal.value = !signal.value;
};
const play = () => {
  isPaused.value = false;
  timeout = setInterval(() => updateSignal(), props.interval);
};

onMounted(() => { play(); });
</script>
