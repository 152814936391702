<template>
  <div>
    <div class="grid lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 text-sm border rounded">
      <div class="text-center border-r p-4 align-top border-b">
        <label>
          <input
            v-model="laptop"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Laptop
        </label>
      </div>
      <div class="text-center border-r-0 sm:border-r p-4 align-top border-b">
        <label>
          <input
            v-model="tablet"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Tablet
        </label>
      </div>
      <div
        :class="[
          'text-center lg:border-r p-4 align-top border-b',
          'sm:border-r-0 lg:border-r border-r',
        ]"
      >
        <label>
          <input
            v-model="smartphone"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Smartphone
        </label>
      </div>
      <div class="text-center border-r-0 sm:border-r p-4  align-top border-b">
        <label>
          <input
            v-model="television"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Television
        </label>
      </div>
      <div class="text-center border-r p-4  align-top border-b">
        <label>
          <input
            v-model="gameConsoles"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Game Consoles
        </label>
      </div>
      <div class="text-center p-4  align-top border-b">
        <label>
          <input
            v-model="camera"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Camera
        </label>
      </div>
      <div class="text-center border-b lg:border-b-0 border-r p-4 align-top">
        <label>
          <input
            v-model="bicycle"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Bicycle
        </label>
      </div>
      <div class="text-center border-b lg:border-b-0 border-r-0 sm:border-r p-4  align-top">
        <label>
          <input
            v-model="sportsEquipment"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Sports Equipment
        </label>
      </div>
      <div
        class="text-center border-b lg:border-b-0 sm:border-r-0 lg:border-r border-r p-4 align-top"
      >
        <label>
          <input
            v-model="clothes"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Clothes
        </label>
      </div>
      <div class="text-center border-b sm:border-b-0 border-r-0 sm:border-r p-4  align-top">
        <label>
          <input
            v-model="jewelryWatches"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Jewelry / Watches
        </label>
      </div>
      <div class="text-center border-r p-4  align-top">
        <label>
          <input
            v-model="textbooks"
            type="number"
            class="w-full h-10 mb-2 rounded p-2 text-center border"
          >
          Textbooks
        </label>
      </div>
      <div class="text-center  p-4  align-top">
        <label>
          <input
            class="w-full h-10 mb-2 rounded p-2 text-center border"
            :value="currencyFormat(defaultPrice, { maximumFractionDigits: 0 })"
            disabled="true"
          >
          Additional Items
        </label>
      </div>
    </div>
    <div
      :class="[
        'flex items-center justify-around',
        'rounded bg-primary-darker text-gray-300 px-4 py-4 mt-4',
      ]"
    >
      <div>
        Estimated Total Cost of Belongings:
        <span class="text-lg font-bold">
          {{ currencyFormat(totalCost(), { maximumFractionDigits: 0 }) }}
        </span>
      </div>
      <div>
        <a
          :class="[
            'w-full bg-primary border border-transparent rounded-md py-2 px-4',
            'flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600',
            'focus:outline-none focus:ring-2 focus:ring-offset-2',
            'focus:ring-offset-gray-800 focus:ring-indigo-500',
          ]"
          :href="getQuoteURL"
        >
          Get a Quote
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { string } from 'vue-types';

import currencyFormat from '@/plugin/functions/currencyFormat';

const sumValues = (values: (number | undefined)[]) => {
  let total = 0;
  for (let index = 0; index < values.length; index += 1) {
    const value = values[index];
    if (value) {
      total += +value;
    }
  }
  return total;
};

export default defineComponent({
  props: {
    quoteUrl: string().isRequired,
  },
  setup() {
    const laptop = ref<number>();
    const tablet = ref<number>();
    const smartphone = ref<number>();
    const television = ref<number>();
    const gameConsoles = ref<number>();
    const camera = ref<number>();
    const bicycle = ref<number>();
    const sportsEquipment = ref<number>();
    const clothes = ref<number>();
    const jewelryWatches = ref<number>();
    const textbooks = ref<number>();
    const defaultPrice = 1000;

    return {
      laptop,
      tablet,
      smartphone,
      television,
      gameConsoles,
      camera,
      bicycle,
      sportsEquipment,
      clothes,
      jewelryWatches,
      textbooks,
      defaultPrice,
      currencyFormat,
    };
  },

  computed: {
    getQuoteURL() {
      const cost = this.totalCost();
      if (cost > 1000) {
        return this.quoteUrl.indexOf('?')
          ? `${this.quoteUrl}&property=${cost}`
          : `${this.quoteUrl}?property=${cost}`;
      }
      return this.quoteUrl;
    },
  },

  methods: {
    totalCost() {
      return sumValues([
        this.defaultPrice,
        this.laptop,
        this.tablet,
        this.smartphone,
        this.television,
        this.gameConsoles,
        this.camera,
        this.bicycle,
        this.sportsEquipment,
        this.clothes,
        this.jewelryWatches,
        this.textbooks,
      ]);
    },
  },
});
</script>

<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
