<template>
  <button
    :class="[
      'flex items-center w-full px-3 border leading-5 rounded-md h-10',
      'space-x-2 text-primary-lighter border-primary-lighter',
      'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
      'sm:text-sm',
    ]"
    type="button"
    @click.prevent="click"
  >
    <i class="fas fa-search text-lg" aria-hidden="true" />
    <span>
      {{ placeholder }}
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { func, string } from 'vue-types';

export default defineComponent({
  name: 'SearchSchoolInputInternal',
  props: {
    click: func<() => void>().isRequired,
    placeholder: string().isRequired,
  },
});
</script>
