<template>
  <div class="flex max-w-2xl mx-auto">
    <div class="w-1/2 pr-4 border-r border-gg-blue-200 mr-8 ml-2">
      <div>
        <h3 class="text-lg text-left tracking-wide font-medium text-primary">
          Renters Insurance
        </h3>
        <h5 class="text-xs text-left text-gray-700">
          Common Articles
        </h5>
      </div>
      <ul class="flex-grow space-y-10 text-left mt-6 mb-10 list-disc ml-4 text-gg-blue-200">
        <li>
          <a
            href="/support/faq/360033648351"
            class="font-medium text-gray-800 block hover:underline"
          >
            What is covered with GradGuard Renters Insurance?
          </a>
        </li>
        <li>
          <a
            href="/support/faq/360058633991"
            class="font-medium text-gray-800 block hover:underline"
          >
            How do I update my billing information?
          </a>
        </li>
        <li>
          <a
            href="/support/faq/360041743451"
            class="font-medium text-gray-800 block hover:underline"
          >
            Cancelling your Renters Insurance policy through your online account
          </a>
        </li>
      </ul>
      <a
        href="/support/faq/renters"
        aria-label="Link to all GradGuard renters insurance FAQ articles on support page"
        class="hover:border-gg-blue-500 inline-flex items-end px-4 py-1 border border-gg-blue-300 text-gg-blue-800 text-base font-medium rounded-md shadow-sm"
      >
        <small>View All Renters Articles</small>
      </a>
    </div>

    <div class="w-1/2 px-0">
      <div>
        <h3 class="text-lg text-left tracking-wide font-medium text-primary">
          Tuition Insurance
        </h3>
        <h5 class="text-xs text-left text-gray-700">
          Common Articles
        </h5>
      </div>
      <ul class="flex-grow text-left space-y-10 mt-6 mb-10 list-disc text-gg-blue-200">
        <li>
          <a
            href="/support/faq/360022066831"
            class="font-medium text-gray-800 block hover:underline"
          >
            What is Tuition Insurance? What does Tuition Insurance cover?
          </a>
        </li>
        <li>
          <a
            href="/support/faq/360022067511"
            class="font-medium text-gray-800 block hover:underline"
          >
            Does Tuition Insurance cover pre-existing conditions?
          </a>
        </li>
        <li>
          <a
            href="/support/faq/360046104351"
            class="font-medium text-gray-800 block hover:underline"
          >
            Does Tuition Insurance cover COVID-19 / Coronavirus?
          </a>
        </li>
      </ul>
      <a
        href="/support/faq/tuition"
        aria-label="Link to all GradGuard tuition insurance FAQ articles on support page"
        class="hover:border-gg-blue-500 inline-flex items-end px-4 py-1 border border-gg-blue-300 text-gg-blue-800 text-base font-medium rounded-md shadow-sm"
      >
        <small>View All Tuition Articles</small>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
