<!-- eslint-disable vue/max-len -->
<template>
  <Disclosure v-slot="{ open }">
    <DisclosureButton class="text-left w-full sticky border-t border-b border-gray-200 bg-gray-50 px-6 py-5 text-sm font-medium text-gray-500">
      <span class="text-lg font-medium text-gray-900">
        {{ title }}
      </span>
      <i class="fas fa-chevron-down px-4" :class="open ? 'transform rotate-180' : ''" />
    </DisclosureButton>

    <DisclosurePanel class="content-text">
      <ul v-for="state in states" :key="state.name" class="relative">
        <li class="bg-white px-6 py-2 hover:bg-gray-50 my-0 py-0">
          <p class="text-sm font-medium text-gray-900">
            {{ state.name }}
          </p>
          <p class="text-sm text-gray-500">
            BUSINESS ENTITY/INDIVIDUAL: {{ state.entity }}
          </p>
          <p class="text-sm text-gray-500">
            LICENSE NUMBER: {{ state.licenseNumber }}
          </p>
        </li>
      </ul>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    states: {
      type: Object,
      default: null,
    },
  },
});
</script>
