<template>
  <div>
    <ContactForm
      ref="contactForm"
      :error="error"
      :loading="isLoading"
      :initial-values="initialValues"
      :is-email-sent="isEmailSent"
      :on-submit="submit"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onUnmounted, ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { object } from 'vue-types';
import { useForm } from 'vee-validate';
import { usePost, ApiResponse } from 'use-api-vue';

import ContactForm from './ContactForm.vue';
import { FormValues } from './ContactFormValues';
import { ServerErrors, ServerResponse } from '@/plugin/ajax/ApiResponse';
import MessageProperties from '@/global/interface';

export default defineComponent({
  name: 'Contact',
  components: {
    ContactForm,
  },
  props: {
    initialValues: object<FormValues>(),
  },
  setup() {
    const isEmailSent = ref(false);
    const post = usePost({ onCancelCallback: onUnmounted });
    const {
      isPending, mutateAsync: send, error,
    } = useMutation<ApiResponse<ServerResponse<MessageProperties>>, ServerErrors, FormValues>({
      mutationFn: (params) => post<ServerResponse<MessageProperties>>('/api/contact', params),
    });

    const contactForm = ref<ReturnType<typeof useForm>>();

    const submit = async (formValues: FormValues) => {
      isEmailSent.value = false;
      const onSuccess = (response: ApiResponse<ServerResponse<MessageProperties>>) => {
        if (response.data?.data.type === 'success') {
          isEmailSent.value = true;
          contactForm.value?.resetForm();
        }
      };
      const response = await send(formValues, { onSuccess });
      return response.data.data.type === 'success';
    };

    return {
      isLoading: isPending,
      error,
      submit,
      isEmailSent,
    };
  },
});
</script>
