export const rentersLogos = [
  { src: 'https://media.gradguard.com/logos/school/sc.svg', alt: 'University of South Carolina-Columbia logo' },
  { src: 'https://media.gradguard.com/logos/school/colorado.svg', alt: 'University of Colorado Boulder logo' },
  { src: 'https://media.gradguard.com/logos/school/gcu.svg', alt: 'Grand Canyon University logo' },
  { src: 'https://media.gradguard.com/logos/school/usc.svg', alt: 'University of Southern California logo' },
  { src: 'https://media.gradguard.com/logos/school/howard.svg', alt: 'Howard University logo' },
  { src: 'https://media.gradguard.com/logos/school/vcu.svg', alt: 'Virginia Commonwealth University logo' },
  { src: 'https://media.gradguard.com/logos/school/miami.svg', alt: 'University of Miami logo' },
  { src: 'https://media.gradguard.com/logos/school/sdsu.png', alt: 'San Diego State University logo' },
  { src: 'https://media.gradguard.com/logos/school/temple.svg', alt: 'Temple University logo' },
  { src: 'https://media.gradguard.com/logos/school/montana.svg', alt: 'Montana State University - Bozeman logo' },
  { src: 'https://media.gradguard.com/logos/school/wvu.svg', alt: 'West Virginia University logo' },
  { src: 'https://media.gradguard.com/logos/school/lmu.png', alt: 'Loyola Marymount University logo' },
  { src: 'https://media.gradguard.com/logos/school/wfu.svg', alt: 'Wake Forest University logo' },
  { src: 'https://media.gradguard.com/logos/school/sjsu.svg', alt: 'San Jose State University logo' },
  { src: 'https://media.gradguard.com/logos/school/newschool.png', alt: 'The New School logo' },
  { src: 'https://media.gradguard.com/logos/school/georgiasouthern.png', alt: 'Georgia Southern University logo' },
  { src: 'https://media.gradguard.com/logos/school/scu.svg', alt: 'Santa Clara University logo' },
  { src: 'https://media.gradguard.com/logos/school/colum.png', alt: 'Columbia College Chicago logo' },
  { src: 'https://media.gradguard.com/logos/school/ush.png', alt: 'University Student Housing at West Chester University logo' },
  { src: 'https://media.gradguard.com/logos/school/ttu.svg', alt: 'Texas Tech University logo' },
  { src: 'https://media.gradguard.com/logos/school/sandiego.svg', alt: 'University of San Diego logo' },
  { src: 'https://media.gradguard.com/logos/school/usfca.svg', alt: 'University of San Francisco logo' },
  { src: 'https://media.gradguard.com/logos/school/csuchico.png', alt: 'CSU Chico logo' },
  { src: 'https://media.gradguard.com/logos/school/sienaheights.svg', alt: 'Siena Heights University logo' },
  { src: 'https://media.gradguard.com/logos/school/unt.svg', alt: 'University of North Texas logo' },
  { src: 'https://media.gradguard.com/logos/school/loyola.png', alt: 'Loyola University Maryland logo' },
  { src: 'https://media.gradguard.com/logos/school/morehouse.svg', alt: 'Morehouse College logo' },
  { src: 'https://media.gradguard.com/logos/school/uml.svg', alt: 'University of Massachusetts-Lowell logo' },
  { src: 'https://media.gradguard.com/logos/school/emu.svg', alt: 'Eastern Michigan University logo' },
  { src: 'https://media.gradguard.com/logos/school/umt.svg', alt: 'University of Montana (Missoula) logo' },
  { src: 'https://media.gradguard.com/logos/school/humboldt.png', alt: 'Humboldt State University logo' },
  { src: 'https://media.gradguard.com/logos/school/gcsu.svg', alt: 'Georgia College and State University logo' },
  { src: 'https://media.gradguard.com/logos/school/valdosta.png', alt: 'Valdosta State University logo' },
  { src: 'https://media.gradguard.com/logos/school/csus.svg', alt: 'Sacramento State University logo' },
  { src: 'https://media.gradguard.com/logos/school/tcnj.svg', alt: 'The College of New Jersey logo' },
  { src: 'https://media.gradguard.com/logos/school/sfsu.png', alt: 'San Francisco State University logo' },
  { src: 'https://media.gradguard.com/logos/school/depaul.svg', alt: 'DePaul University logo' },
  { src: 'https://media.gradguard.com/logos/school/wpi.svg', alt: 'Worcester Polytechnic Institute logo' },
  { src: 'https://media.gradguard.com/logos/school/reed.svg', alt: 'Reed College logo' },
  { src: 'https://media.gradguard.com/logos/school/pointloma.svg', alt: 'Point Loma Nazarene University logo' },
  { src: 'https://media.gradguard.com/logos/school/csu-la.png', alt: 'California State University-Los Angeles logo' },
  { src: 'https://media.gradguard.com/logos/school/csulb.svg', alt: 'California State University-Long Beach logo' },
  { src: 'https://media.gradguard.com/logos/school/fandm.svg', alt: 'Franklin and Marshall College logo' },
  { src: 'https://media.gradguard.com/logos/school/duq.png', alt: 'Duquesne University logo' },
  { src: 'https://media.gradguard.com/logos/school/michigan.svg', alt: 'University of Michigan-Ann Arbor logo' },
  { src: 'https://media.gradguard.com/logos/school/stedwards.svg', alt: 'St. Edward\'s University logo' },
  { src: 'https://media.gradguard.com/logos/school/fortlewis.png', alt: 'Fort Lewis College logo' },
  { src: 'https://media.gradguard.com/logos/school/unomaha.svg', alt: 'University of Nebraska at Omaha logo' },
  { src: 'https://media.gradguard.com/logos/school/calpoly.svg', alt: 'California Polytechnic State University-San Luis Obispo logo' },
  { src: 'https://media.gradguard.com/logos/school/utulsa.png', alt: 'University of Tulsa logo' },
  { src: 'https://media.gradguard.com/logos/school/bsu.svg', alt: 'Ball State University logo' },
  { src: 'https://media.gradguard.com/logos/school/fullerton.svg', alt: 'California State University-Fullerton logo' },
  { src: 'https://media.gradguard.com/logos/school/jefferson.png', alt: 'Thomas Jefferson University logo' },
  { src: 'https://media.gradguard.com/logos/school/indiana.svg', alt: 'Indiana University-Bloomington logo' },
  { src: 'https://media.gradguard.com/logos/school/wheaton.svg', alt: 'Wheaton College - IL logo' },
  { src: 'https://media.gradguard.com/logos/school/pitt.png', alt: 'University of Pittsburgh - Pittsburgh Campus logo' },
  { src: 'https://media.gradguard.com/logos/school/lindenwood.png', alt: 'Lindenwood University logo' },
  { src: 'https://media.gradguard.com/logos/school/carroll.png', alt: 'Carroll University logo' },
  { src: 'https://media.gradguard.com/logos/school/abrahambaldwin.svg', alt: 'Abraham Baldwin Agricultural College logo' },
  { src: 'https://media.gradguard.com/logos/school/denison.png', alt: 'Denison University logo' },
  { src: 'https://media.gradguard.com/logos/school/gwu.png', alt: 'George Washington University logo' },
  { src: 'https://media.gradguard.com/logos/school/uwa.svg', alt: 'University of West Alabama logo' },
  { src: 'https://media.gradguard.com/logos/school/wilkes.svg', alt: 'Wilkes University logo' },
  { src: 'https://media.gradguard.com/logos/school/cn.png', alt: 'Carson-Newman University logo' },
  { src: 'https://media.gradguard.com/logos/school/nku.svg', alt: 'Northern Kentucky University logo' },
  { src: 'https://media.gradguard.com/logos/school/whittier.svg', alt: 'Whittier College logo' },
  { src: 'https://media.gradguard.com/logos/school/northwestern.svg', alt: 'Northwestern University logo' },
  { src: 'https://media.gradguard.com/logos/school/dean.svg', alt: 'Dean College logo' },
  { src: 'https://media.gradguard.com/logos/school/bellevue.png', alt: 'Bellevue University logo' },
  { src: 'https://media.gradguard.com/logos/school/missouriwestern.svg', alt: 'Missouri Western State University logo' },
  { src: 'https://media.gradguard.com/logos/school/guilford.png', alt: 'Guilford College logo' },
  { src: 'https://media.gradguard.com/logos/school/loyno.svg', alt: 'Loyola University New Orleans logo' },
  { src: 'https://media.gradguard.com/logos/school/untdallas.svg', alt: 'University of North Texas at Dallas logo' },
  { src: 'https://media.gradguard.com/logos/school/umwestern.png', alt: 'The University of Montana - Western logo' },
  { src: 'https://media.gradguard.com/logos/school/bsc.svg', alt: 'Birmingham Southern College logo' },
  { src: 'https://media.gradguard.com/logos/school/messiah.png', alt: 'Messiah University logo' },
  { src: 'https://media.gradguard.com/logos/school/fairmont.svg', alt: 'Fairmont State University logo' },
  { src: 'https://media.gradguard.com/logos/school/umes.png', alt: 'University of Maryland Eastern Shore logo' },
  { src: 'https://media.gradguard.com/logos/school/csumb.png', alt: 'California State University-Monterey Bay  logo' },
  { src: 'https://media.gradguard.com/logos/school/wcsu.png', alt: 'Western Connecticut State University logo' },

];

export const tuitionLogos = [
  { src: 'https://media.gradguard.com/logos/school/miamoh.svg', alt: 'Miami University logo' },
  { src: 'https://media.gradguard.com/logos/school/creighton.svg', alt: 'Creighton University logo' },
  { src: 'https://media.gradguard.com/logos/school/utk.svg', alt: 'The University of Tennessee-Knoxville logo' },
  { src: 'https://media.gradguard.com/logos/school/auburn.svg', alt: 'Auburn University logo' },
  { src: 'https://media.gradguard.com/logos/school/scad.png', alt: 'Savannah College of Art and Design logo' },
  { src: 'https://media.gradguard.com/logos/school/purdue.svg', alt: 'Purdue University-Main Campus logo' },
  { src: 'https://media.gradguard.com/logos/school/brandeis_2.png', alt: 'Brandeis University logo' },
  { src: 'https://media.gradguard.com/logos/school/andersonuniversity.svg', alt: 'Anderson University - SC logo' },
  { src: 'https://media.gradguard.com/logos/school/nyu.svg', alt: 'New York University logo' },
  { src: 'https://media.gradguard.com/logos/school/rwu.svg', alt: 'Roger Williams University logo' },
  { src: 'https://media.gradguard.com/logos/school/ttu.svg', alt: 'Texas Tech University logo' },
  { src: 'https://media.gradguard.com/logos/school/berry.svg', alt: 'Berry College logo' },
  { src: 'https://media.gradguard.com/logos/school/wheatoncollege.png', alt: 'Wheaton College - MA logo' },
  { src: 'https://media.gradguard.com/logos/school/baylor.svg', alt: 'Baylor University logo' },
  { src: 'https://media.gradguard.com/logos/school/furman.svg', alt: 'Furman University logo' },
  { src: 'https://media.gradguard.com/logos/school/sju.svg', alt: 'Saint Joseph\'s University logo' },
  { src: 'https://media.gradguard.com/logos/school/uncc.png', alt: 'University of North Carolina at Charlotte - NC logo' },
  { src: 'https://media.gradguard.com/logos/school/stedwards.svg', alt: 'St. Edward\'s University logo' },
  { src: 'https://media.gradguard.com/logos/school/rockhurst.svg', alt: 'Rockhurst University logo' },
  { src: 'https://media.gradguard.com/logos/school/anselm.svg', alt: 'Saint Anselm College logo' },
  { src: 'https://media.gradguard.com/logos/school/uncw.png', alt: 'University of North Carolina Wilmington - NC logo' },
  { src: 'https://media.gradguard.com/logos/school/lesley.png', alt: 'Lesley University logo' },
  { src: 'https://media.gradguard.com/logos/school/appstate.png', alt: 'Appalachian State University - NC logo' },
  { src: 'https://media.gradguard.com/logos/school/american.svg', alt: 'American University logo' },
  { src: 'https://media.gradguard.com/logos/school/linfield_2.png', alt: 'Linfield University logo' },
  { src: 'https://media.gradguard.com/logos/school/okstate.svg', alt: 'Oklahoma State University logo' },
  { src: 'https://media.gradguard.com/logos/school/oberlin.svg', alt: 'Oberlin College logo' },
  { src: 'https://media.gradguard.com/logos/school/assumption-university.svg', alt: 'Assumption University logo' },
  { src: 'https://media.gradguard.com/logos/school/rit.svg', alt: 'Rochester Institute of Technology logo' },
  { src: 'https://media.gradguard.com/logos/school/redlands.png', alt: 'University of Redlands logo' },
  { src: 'https://media.gradguard.com/logos/school/risd.svg', alt: 'Rhode Island School of Design logo' },
  { src: 'https://media.gradguard.com/logos/school/depaul.svg', alt: 'DePaul University logo' },
  { src: 'https://media.gradguard.com/logos/school/chapman.png', alt: 'Chapman University logo' },
  { src: 'https://media.gradguard.com/logos/school/st-thomas.svg', alt: 'University of St Thomas - MN logo' },
  { src: 'https://media.gradguard.com/logos/school/fairfield.png', alt: 'Fairfield University - CT logo' },
  { src: 'https://media.gradguard.com/logos/school/owu.svg', alt: 'Ohio Wesleyan University logo' },
  { src: 'https://media.gradguard.com/logos/school/wpi.svg', alt: 'Worcester Polytechnic Institute logo' },
  { src: 'https://media.gradguard.com/logos/school/missouri.png', alt: 'University of Missouri-Columbia - MO logo' },
  { src: 'https://media.gradguard.com/logos/school/ccu.svg', alt: 'Colorado Christian University logo' },
  { src: 'https://media.gradguard.com/logos/school/endicott.svg', alt: 'Endicott College logo' },
  { src: 'https://media.gradguard.com/logos/school/bridgew.png', alt: 'Bridgewater State University - MA logo' },
  { src: 'https://media.gradguard.com/logos/school/dean.svg', alt: 'Dean College logo' },
  { src: 'https://media.gradguard.com/logos/school/occidental.svg', alt: 'Occidental College logo' },
  { src: 'https://media.gradguard.com/logos/school/simmons.svg', alt: 'Simmons University logo' },
  { src: 'https://media.gradguard.com/logos/school/unh.svg', alt: 'University of New Hampshire logo' },
  { src: 'https://media.gradguard.com/logos/school/tulane.svg', alt: 'Tulane University logo' },
  { src: 'https://media.gradguard.com/logos/school/clarku.svg', alt: 'Clark University - MA logo' },
  { src: 'https://media.gradguard.com/logos/school/allegheny.svg', alt: 'Allegheny College logo' },
  { src: 'https://media.gradguard.com/logos/school/manhattan.svg', alt: 'Manhattan College logo' },
  { src: 'https://media.gradguard.com/logos/school/kcai.svg', alt: 'Kansas City Art Institute logo' },
  { src: 'https://media.gradguard.com/logos/school/mtholyoke.svg', alt: 'Mount Holyoke College logo' },
  { src: 'https://media.gradguard.com/logos/school/drew.svg', alt: 'Drew University logo' },
  { src: 'https://media.gradguard.com/logos/school/champlain.svg', alt: 'Champlain College logo' },
  { src: 'https://media.gradguard.com/logos/school/stonehill.svg', alt: 'Stonehill College logo' },
  { src: 'https://media.gradguard.com/logos/school/winthrop.svg', alt: 'Winthrop University logo' },
  { src: 'https://media.gradguard.com/logos/school/utc.png', alt: 'The University of Tennessee-Chattanooga logo' },
  { src: 'https://media.gradguard.com/logos/school/fit.svg', alt: 'Florida Institute of Technology logo' },
  { src: 'https://media.gradguard.com/logos/school/ramapo.png', alt: 'Ramapo College of New Jersey logo' },
  { src: 'https://media.gradguard.com/logos/school/emory.svg', alt: 'Emory University logo' },
  { src: 'https://media.gradguard.com/logos/school/uarts.svg', alt: 'The University of the Arts logo' },
  { src: 'https://media.gradguard.com/logos/school/wvu.svg', alt: 'West Virginia University logo' },
  { src: 'https://media.gradguard.com/logos/school/brown.svg', alt: 'Brown University logo' },
  { src: 'https://media.gradguard.com/logos/school/pba.png', alt: 'Palm Beach Atlantic University logo' },
  { src: 'https://media.gradguard.com/logos/school/beloit.svg', alt: 'Beloit College logo' },
  { src: 'https://media.gradguard.com/logos/school/loyno.svg', alt: 'Loyola University New Orleans logo' },
  { src: 'https://media.gradguard.com/logos/school/kent.svg', alt: 'Kent State University logo' },
  { src: 'https://media.gradguard.com/logos/school/case.png', alt: 'Case Western Reserve University logo' },
  { src: 'https://media.gradguard.com/logos/school/fortlewis.png', alt: 'Fort Lewis College logo' },
  { src: 'https://media.gradguard.com/logos/school/jmu.svg', alt: 'James Madison University logo' },
  { src: 'https://media.gradguard.com/logos/school/uncsa.svg', alt: 'University of North Carolina School of the Arts - NC logo' },
  { src: 'https://media.gradguard.com/logos/school/samford.png', alt: 'Samford University - AL logo' },
  { src: 'https://media.gradguard.com/logos/school/conncoll.svg', alt: 'Connecticut College logo' },
  { src: 'https://media.gradguard.com/logos/school/strose.svg', alt: 'The College of Saint Rose logo' },
  { src: 'https://media.gradguard.com/logos/school/virginia.svg', alt: 'University of Virginia logo' },
  { src: 'https://media.gradguard.com/logos/school/pnw.png', alt: 'Purdue University Northwest logo' },
  { src: 'https://media.gradguard.com/logos/school/richmond.svg', alt: 'University of Richmond logo' },
  { src: 'https://media.gradguard.com/logos/school/salisbury.svg', alt: 'Salisbury University logo' },
  { src: 'https://media.gradguard.com/logos/school/lasell.png', alt: 'Lasell University logo' },
  { src: 'https://media.gradguard.com/logos/school/callutheran.svg', alt: 'California Lutheran University logo' },
  /*
    { src: 'https://media.gradguard.com/logos/school/uvm.svg', alt: 'University of Vermont logo' },
    // no more partner
  */

];
