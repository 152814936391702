export interface Testimony {
  headline: string,
  subtitle: string,
  reviewer: string,
  color: 'purple' | 'red' | 'green',
}

export const colorsMap: Record<Testimony['color'], string> = {
  purple: 'bg-indigo-100 text-indigo-800',
  red: 'bg-pink-100 text-pink-800',
  green: 'bg-green-100 text-green-800',
};

export const testimonies: Testimony[] = [
  {
    headline: 'Great Customer Service!',
    subtitle: 'Superior follow up, thanks so much for making me feel like I matter!',
    reviewer: 'Jen K.',
    color: 'purple',
  },
  {
    headline: 'Highest marks for customer service.',
    subtitle: 'Super responsive and thorough, quick and pleasant, for resolving any questions.',
    reviewer: 'David H.',
    color: 'red',
  },
  {
    headline: 'Great customer service, Emma! Thank you.',
    subtitle: 'I emailed a question and got both a quick response and then a follow-up email as well.',
    reviewer: 'Beth K.',
    color: 'green',
  },
  {
    headline: 'Very pleasant and patient throughout the process. Very professional!',
    subtitle: 'Both Ryan and Emily were very thorough in helping me resolve my issue.',
    reviewer: 'Jackson S.',
    color: 'purple',
  },
  {
    headline: 'Outstanding customer service and dedication to their clients. Five stars!',
    subtitle: 'Nyeedra and the team showed remarkable understanding and flexibility. I highly recommend GradGuard!',
    reviewer: 'Maria P.',
    color: 'red',
  },
  {
    headline: 'Easy to do what I needed to do.',
    subtitle: 'Victoria was super helpful!',
    reviewer: 'Bert B.',
    color: 'green',
  },
  {
    headline: 'Customer service was great.',
    subtitle: 'Thank you very much Emily for your help and providing me with the requested information.',
    reviewer: 'Mohammed K.',
    color: 'purple',
  },
  {
    headline: 'Great company to work with.',
    subtitle: 'Great customer service.',
    reviewer: 'Hemakshi P.',
    color: 'red',
  },
  {
    headline: 'So simple and easy without a fuss.',
    subtitle: 'That was a pleasant experience and quickly taken care of.',
    reviewer: 'Neal J.',
    color: 'green',
  },
  {
    headline: 'Quick, accurate and pleasant response.',
    subtitle: 'Can’t ask for more than that.',
    reviewer: 'Steve M.',
    color: 'purple',
  },
  {
    headline: 'Lowest price I found for lowest deductible and best coverage.',
    subtitle: 'Great value. All I’ve talked to and emailed with have been extremely friendly (Tatum, Ryan and Victoria) and prompt in replies. Extremely impressed!',
    reviewer: 'B.H.',
    color: 'red',
  },
  {
    headline: 'Patient, kind, and conscientious.',
    subtitle: 'Thankfully, in a stressful situation, the representative who talked with me was patient, kind, and conscientious.',
    reviewer: 'Laurie A.',
    color: 'green',
  },
  {
    headline: 'GradGuard customer service is pretty fantastic.',
    subtitle: 'The person I spoke with was knowledgable and answered all of my questions with ease. She was so nice and seemed truly caring. I highly recommend reaching out to GradGuard. All in all, a great customer service experience!',
    reviewer: 'Jennifer M.',
    color: 'purple',
  },
  {
    headline: 'Super impressed!',
    subtitle: 'I had, what I thought, was something that would take a lot of time. I sent a ‘help’ request with my information and it was completed and I received an email back within an hour or so!',
    reviewer: 'Shelly B.',
    color: 'red',
  },
  {
    headline: 'Customer service was great!',
    subtitle: 'They pro-actively corrected an error I made and reached out to notify me. You don’t find service like that anymore.',
    reviewer: 'Richard G.',
    color: 'green',
  },
  {
    color: 'purple',
    headline: 'Great company to be in business with.',
    subtitle: 'They are one of the few companies that provide excellent coverage for a low cost yet providing premium insurance and customer service all while treating you like an extended family member! I feel comfortable knowing what to do if something were to happen.',
    reviewer: 'Jno858',
  },
  {
    color: 'red',
    headline: 'Easy to use website and excellent customer service.',
    subtitle: 'This is our second year insuring our daughter’s tuition and renter’s insurance and GradGuard along with their team are simply amazing!',
    reviewer: 'Mack H.',
  },
  {
    color: 'green',
    headline: 'Nice to have and prices are affordable.',
    subtitle: 'The lady I spoke to was very helpful! I had my plan registered to whatever dorm I was in college, which was helpful because the policy followed whatever dorm the college had on file for me. Overall it was nice to have.',
    reviewer: 'Nick S.',
  },
];
