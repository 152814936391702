<template>
  <div id="carousel">
    <!-- Image Navigation Buttons -->
    <div>
      <button
        v-for="index in 6"
        :key="index"
        type="button"
        class="image-button"
        title="Click to view slideshow"
        :aria-label="`Go to slide ${index}`"
        tabindex="0"
        @click="goToNextImage"
      >
        <img
          :src="`/images/careers/companyphotoCarousel${index}.png`"
          :alt="`Image ${index}`"
          :class="{
            'carousel-image': true,
            active: activeIndex === index - 1,
            'aspect-auto': true,
            'object-fit': true,
            'flex-auto': true,
            'grow-0': true,
          }"
        >
      </button>
    </div>
    <!-- Dot Navigation Controls -->
    <div class="flex justify-center space-x-2 mt-2 md:mt-4">
      <button
        v-for="index in 6"
        :key="`dot-${index}`"
        type="button"
        :class="buttonClass(index - 1)"
        :aria-label="`Go to slide ${index}`"
        tabindex="0"
        title="Click to view slideshow"
        @click="updateActiveImage(index - 1)"
        @keydown.enter="updateActiveImage(index - 1)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const activeIndex = ref(0);

const goToNextImage = () => {
  activeIndex.value = (activeIndex.value + 1) % 6; // Assuming there are 6 images
};

const updateActiveImage = (index: number) => {
  activeIndex.value = index;
};

const buttonClass = (index: number) => ({
  'w-8 h-2': activeIndex.value === index,
  'w-2 h-2': activeIndex.value !== index,
  'rounded-full': true,
  'bg-gg-blue-600': true,
  'focus:outline-none': true,
  'focus:ring-2': true,
  'focus:ring-offset-2': true,
  'focus:ring-gg-blue-600': true,
  'hover:opacity-75': true,
});
</script>

<style scoped>
.carousel-image {
  display: none;
}
.carousel-image.active {
  display: block;
}
.image-button {
  background: none;
  border: none;
  padding: 0;
}
.image-button:hover {
  outline: 2px solid transparent;
}
</style>
