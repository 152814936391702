<template>
  <div id="testimonial-carousel" class="mx-auto overflow-hidden py-10 rounded-lg px-0 md:px-8 md:ml-8">
    <div v-for="(testimonial, index) in testimonials" :key="testimonial.id" class="testimonial-slide flex-col" :class="{ active: activeIndex === index }">
      <div class="flex flex-col justify-center items-center md:flex-row">
        <div class="w-fit mx-auto relative">
          <div class="absolute top-0 left-0 -translate-x-8 overflow-visible -translate-y-24 z-20">
            <div class="font-title relative overflow-visible" style="font-size: 18em">
              <span class="drop-shadow-lg text-gg-blue-600">“</span>
            </div>
          </div>
          <!-- Author image -->
          <img :src="testimonial.image" alt="Author" class="w-44 flex-grow-0 flex-shrink-0 rounded-full aspect-auto z-10" />
        </div>
        <div class="flex-col px-8 min-[425px]:px-12 mt-4">
          <blockquote class="tracking-normal max-w-lg text-balance font-normal text-lg leading-relaxed">
            {{ testimonial.quote }}
          </blockquote>
          <p class="mt-6 text-base font-normal text-left text-gg-blue-600">
            {{ testimonial.author }}
          </p>
          <!-- Dot Navigation Controls -->
          <div class="flex justify-center md:justify-start space-x-3 mt-10">
            <button
              v-for="(testimonialNav, indexNav) in testimonials"
              :key="`dot-${testimonialNav.id}`"
              type="button"
              :class="buttonClass(indexNav)"
              :aria-label="`Go to slide ${indexNav + 1}`"
              tabindex="0"
              title="Click to see what our employees have to say"
              @click="updateActiveImage(indexNav)"
              @keydown.enter="updateActiveImage(indexNav)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const testimonials = ref([
  {
    id: 1,
    image: '/images/careers/Emily.png',
    quote: 'GradGuard really strives in helping you grow in your professional development and as a recent grad, I value that the most. I am now responsible for the Member Experience training program and leading of the team in our day to day work.',
    author: 'EMILY, AGENCY',
  },
  {
    id: 2,
    image: '/images/careers/Emma.png',
    quote: 'The best part about working at GradGuard is the people. Being surrounded by teammates who challenge me and encourage both personal and professional growth is what makes this culture so unique. We’re committed to prioritizing transparency and honesty not only with each other but with the clients and customers that we’re fortunate enough to work with each and every day.',
    author: 'EMMA, SCHOOL SUCCESS',
  },
  {
    id: 3,
    image: '/images/careers/David.png',
    quote: 'Working at GradGuard means immersing yourself in a role where innovation meets independence, surrounded by a team that thrives on collaboration. It’s a professional journey fueled by advanced technologies and a commitment to personal growth, underpinned by a principled culture of respect.',
    author: 'DAVID, TECHNOLOGY',
  },
]);

const activeIndex = ref(0);

const updateActiveImage = (index: number) => {
  activeIndex.value = index;
};

const buttonClass = (index: number) => ({
  'w-9 h-3': activeIndex.value === index,
  'w-3 h-3': activeIndex.value !== index,
  'rounded-full': true,
  'bg-gg-blue-600': true,
  'focus:outline-none': true,
  'focus:ring-2': true,
  'focus:ring-offset-2': true,
  'focus:ring-gg-blue-600': true,
  'hover:opacity-75': true,
});
</script>

<style scoped>
.testimonial-slide {
  display: none;
}

.testimonial-slide.active {
  display: flex;
}
</style>
