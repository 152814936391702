<template>
  <form
    ref="form"
    @submit="onSubmit"
  >
    <div class="mb-8 pb-4 border-b border-blue-300">
      <h2 class="text-gray-700 text-xl mb-8 font-bold">
        Your Info
      </h2>

      <div class="highlight flex space-x-4">
        <FormItem
          :field="honeyPot1"
          label="First name"
          :required="true"
          class="flex-1"
          for="contactUs-firstname"
          error-id="contactUs-firstname-error"
        >
          <Input
            id="contactUs-firstname"
            v-model="honeyPot1.value.value"
            :errors="honeyPot1.errors.value"
            :disabled="loading"
            aria-label="First name"
            aria-describedby="contactUs-firstname-error"
            @ref="honeyPot1.setRef"
          />
        </FormItem>
        <FormItem
          :field="honeyPot2"
          label="Last name"
          :required="true"
          class="flex-1"
          for="contactUs-lastname"
          error-id="contactUs-lastname-error"
        >
          <Input
            id="contactUs-lastname"
            v-model="honeyPot2.value.value"
            :errors="honeyPot2.errors.value"
            :disabled="loading"
            aria-label="Last name"
            aria-describedby="contactUs-lastname-error"
            @ref="honeyPot2.setRef"
          />
        </FormItem>
      </div>

      <div class="sm:flex sm:space-x-5">
        <FormItem
          :field="referrerName"
          label="Your name"
          :required="true"
          class="flex-1"
        >
          <Input
            v-model="referrerName.value.value"
            :errors="referrerName.errors.value"
            @ref="referrerName.setRef"
          />
        </FormItem>
        <FormItem
          :field="referrerEmail"
          label="Your email"
          :required="true"
          class="flex-1"
        >
          <Input
            v-model="referrerEmail.value.value"
            :errors="referrerEmail.errors.value"
            @ref="referrerEmail.setRef"
          />
        </FormItem>
      </div>
      <div>
        <FormItem :field="referrerSchool" label="School name" :required="true">
          <Input
            v-model="referrerSchool.value.value"
            :errors="referrerSchool.errors.value"
            @ref="referrerSchool.setRef"
          />
        </FormItem>
      </div>
    </div>
    <div>
      <h2 class="text-gray-700 text-xl my-4 font-bold">
        Your Colleague's Info
      </h2>
      <div class="sm:flex sm:space-x-5">
        <FormItem
          :field="referralFirstName"
          label="First name"
          :required="true"
          class="flex-1"
        >
          <Input
            v-model="referralFirstName.value.value"
            :errors="referralFirstName.errors.value"
            @ref="referralFirstName.setRef"
          />
        </FormItem>
        <FormItem
          :field="referralLastName"
          label="Last name"
          :required="true"
          class="flex-1"
        >
          <Input
            v-model="referralLastName.value.value"
            :errors="referralLastName.errors.value"
            @ref="referralLastName.setRef"
          />
        </FormItem>
      </div>
      <div class="sm:flex sm:space-x-5">
        <FormItem
          :field="referralSchoolName"
          label="School"
          :required="true"
          class="flex-1"
        >
          <Input
            v-model="referralSchoolName.value.value"
            :errors="referralSchoolName.errors.value"
            @ref="referralSchoolName.setRef"
          />
        </FormItem>
        <FormItem
          :field="referralEmail"
          label="Email"
          :required="true"
          class="flex-1"
        >
          <Input
            v-model="referralEmail.value.value"
            :errors="referralEmail.errors.value"
            @ref="referralEmail.setRef"
          />
        </FormItem>
      </div>
    </div>
    <Errors v-if="error" :error="error" />
    <div class="mt-8 text-center">
      <Button
        :loading="loading"
        :disabled="loading"
        text="Submit"
        text-loading="Submitting"
        type="primary"
        html-type="submit"
      />
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as Yup from 'yup';
import { object, oneOfType } from 'vue-types';

import { Input, FormItem } from '../../ui/Form';
import Button from '../../ui/Button/Button.vue';
import Errors from '../errors/errors.vue';
import { FormValues } from './ReferralFormValues';
import useForm from '@/plugin/form/useForm';
import { ServerErrors } from '@/plugin/ajax/ApiResponse';

export default defineComponent({
  name: 'ReferralForm',
  components: {
    FormItem,
    Input,
    Button,
    Errors,
  },

  props: {
    loading: Boolean,
    error: oneOfType<ServerErrors| null>([]),
    initialValues: object<FormValues>(),
  },

  emits: ['submit'],

  setup(props, { emit }) {
    const {
      handleSubmit, resetForm, register, focusError,
    } = useForm({ initialValues: props.initialValues });

    const referrerName = register('referrer_name', Yup.string().trim().required('Your name is required'));
    const referrerEmail = register('referrer_email', Yup.string().email().required('Your email is required'));
    const referrerSchool = register('referrer_school', Yup.string().trim().required('School is required'));
    const referralFirstName = register('referral_first_name', Yup.string().trim().required('First name is required'));
    const referralLastName = register('referral_last_name', Yup.string().trim().required('Last name is required'));
    const referralSchoolName = register('referral_school_name', Yup.string().trim().required('School is required'));
    const referralEmail = register('referral_email', Yup.string().email().required('Email is required'));
    const honeyPot1 = register('firstName');
    const honeyPot2 = register('lastName');

    const onSubmit = handleSubmit(
      (values: FormValues) => {
        if (values.firstName || values.lastName) {
          throw new Error('something went wrong');
        }
        emit('submit', values);
      },
      focusError,
    );

    return {
      onSubmit,
      resetForm,
      referrerName,
      referrerEmail,
      referrerSchool,
      referralFirstName,
      referralLastName,
      referralSchoolName,
      referralEmail,
      honeyPot1,
      honeyPot2,
    };
  },

  methods: {
    submit(values: FormValues) {
      // Honeypot exception
      if (values.firstName || values.lastName) {
        throw new Error('something went wrong');
      }
      this.$emit('submit', values);
    },
    today: () => (new Date()).toISOString().split('T')[0],
  },

});
</script>
