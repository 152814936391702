<script setup lang="ts">
import * as Yup from 'yup';

import { FormItem, Input } from '@/ui/Form';
import useForm from '@/plugin/form/useForm';
import { type SearchProps } from './useArticles';

const props = defineProps<{
  initialValues?: SearchProps,
  loading?: boolean,
  onSubmit:(values: string) => void,
}>();
const {
  handleSubmit, register, focusError,
} = useForm({ initialValues: props.initialValues });

const query = register('query', Yup.string().trim().required('Please enter your search'));

const onSubmitInternal = handleSubmit(
  async (values: SearchProps) => props.onSubmit(values.query),
  focusError,
);
</script>

<template>
  <form @submit.prevent="onSubmitInternal">
    <FormItem :field="query" class="flex-grow">
      <div class="flex flex-grow">
        <Input
          v-model="query.value.value"
          :errors="query.errors.value"
          :disabled="loading"
          :required="true"
          default-class="placeholder-modalGray flex-grow border px-4 text-left py-1 w-full border-gray-500 rounded-l-md"
          placeholder="Search Help Articles"
          aria-label="Search Help Articles"
          @ref="query.setRef"
        />
        <button type="submit" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-500 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          <i class="fas fa-search text-gray-400" />
          <span class="hidden md:inline">Search</span>
        </button>
      </div>
    </FormItem>
  </form>
</template>
