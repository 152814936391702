<template>
  <div
    v-if="isVisible"
    class="sticky top-0 isolate flex items-center gap-x-2 overflow-hidden px-2 py-2 sm:px-3 sm:before:flex-1"
  >
    <div class="flex flex-wrap items-center gap-x-2 gap-y-2 justify-center">
      <p class="text-sm leading-6 text-gray-900 text-center">
        <slot />
      </p>
    </div>
    <div class="flex flex-1 justify-end">
      <button
        type="button"
        class="-m-3 p-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-md hover:shadow-md hover:bg-primary-darker "
        @click="onHide"
      >
        <span class="sr-only">Dismiss</span>
        <XMarkIcon class="w-4 h-4 text-white" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

import BrowserStorage from '@/util/localStorage/BrowserStorage';

const props = defineProps<{ id: string }>();

const browserStorage = new BrowserStorage(
  props.id,
  true,
  'announcement-banner-',
);

const isVisible = ref(browserStorage.get());
const onHide = () => {
  browserStorage.set(false);
  isVisible.value = false;
};
</script>
