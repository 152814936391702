<template>
  <SearchModal
    v-model="inputValue"
    :placeholder="placeholder"
    :total-items="data?.data.data.length"
    :change="onChange"
    :loading="isFetching"
    :error="error ?? undefined"
    :min-length-text="3"
    :on-select-by-index="onSelectByIndex"
    input-id="search-school-input-id"
    :input-aria-control="resultId"
    label="School Search"
  >
    <template #button="{ openModal }">
      <slot name="button" v-bind="{ openModal }">
        <SearchSchoolButton
          v-if="name"
          :open-modal="openModal"
          :logo="logo"
          :name="name"
        />
        <SearchSchoolInput
          v-else
          :click="openModal"
          :placeholder="placeholder"
        />
      </slot>
    </template>
    <template #results="{ selected, onMouseOver }">
      <search-modal-results
        :result-id="resultId"
        :convert="convert"
        :select="select"
        :items="data?.data.data"
        :selected="selected"
        :mouseover="onMouseOver"
      />
    </template>
  </SearchModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { string } from 'vue-types';

import SearchSchoolButton from './search-school-button.vue';
import SearchSchoolInput from './search-school-input.vue';
import { Result } from '@/ui/search-modal/search-modal-results-interface';
import SearchModalResults from '@/ui/search-modal/search-modal-results.vue';
import SearchModal from '@/ui/search-modal/search-modal.vue';
import useSchools, { SchoolProperties } from '@/hooks/useSchools';

export default defineComponent({
  name: 'SearchSchool',
  components: {
    SearchModal,
    SearchSchoolButton,
    SearchSchoolInput,
    SearchModalResults,
  },

  props: {
    logo: string(),
    name: string(),
    placeholder: string().def('Search School'),
    resultId: string().def('search-school-results'),
  },

  setup() {
    const {
      inputValue, isFetching, data, error, onChange,
    } = useSchools();

    return {
      inputValue,
      isFetching,
      data,
      error,
      onChange,
    };
  },

  methods: {
    convert(school: SchoolProperties): Result {
      const address = `${school.address_city} , ${school.address_state}`;
      return {
        id: school.id,
        title: school.school_name,
        description: address,
      };
    },
    select(school: SchoolProperties) {
      window.location.href = `${window.location.pathname}?school_id=${school.id}`;
    },
    onSelectByIndex(index: number) {
      const school = this.data?.data.data[index];
      if (school) {
        window.location.href = `${window.location.pathname}?school_id=${school.id}`;
      }
    },
  },
});
</script>
