export const loadImage = (
  src: string,
  onload?: () => void,
) => new Promise<HTMLImageElement>((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => {
    resolve(image);
    if (onload) {
      onload();
    }
  });
  image.addEventListener('error', (error) => reject(error));
  image.src = src;
});

export const getNextImageIndex = (currentIndex: number, imagesLength: number) => {
  const indexImage = currentIndex + 1;
  if (currentIndex + 1 >= imagesLength) {
    return 0;
  }
  return indexImage;
};

export interface ImagesProperty {
  src: string;
  alt: string;
}
