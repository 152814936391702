<template>
  <TransitionImage
    v-if="image1"
    image-class="absolute max-h-12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    :image1="image1"
    :image2="image2"
    :show-image1="showImage1"
  />
  <div v-else>
    <i
      class="fas fa-spinner fa-spin text-gray-400"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref, computed, watch, onMounted,
} from 'vue';
import { array, string } from 'vue-types';

import { getNextImageIndex, ImagesProperty, loadImage } from './functions';
import TransitionImage from './transition-image.vue';
import { useLoadImage } from './useLoadImages';

enum CurrentDisplayEnum {
  image1='image1',
  image2='image2',
}

const getImage = (images: ImagesProperty[], baseUrl: string, index?: number) => {
  if (index === undefined) {
    return undefined;
  }
  const image = images[index];
  return {
    src: baseUrl + image.src,
    alt: image.alt,
  };
};

const props = defineProps({
  images: array<ImagesProperty>().isRequired,
  baseUrl: string().def(''),
  signal: Boolean,
});

const loaded = useLoadImage();
const imageIndex1 = ref<number>();
const imageIndex2 = ref<number>();
const currentIndex = ref<number>(-1);
const currentDisplay = ref<CurrentDisplayEnum>();

const reset = () => {
  imageIndex1.value = undefined;
  imageIndex2.value = undefined;
  currentIndex.value = -1;
  currentDisplay.value = undefined;
};

const setImageIndex = (indexImage: number) => {
  currentIndex.value = indexImage;
  if (currentDisplay.value === CurrentDisplayEnum.image1) {
    imageIndex2.value = indexImage;
    currentDisplay.value = CurrentDisplayEnum.image2;
  } else {
    imageIndex1.value = indexImage;
    currentDisplay.value = CurrentDisplayEnum.image1;
  }
};

const setImage = async () => {
  const indexImage = getNextImageIndex(currentIndex.value, props.images.length);
  const { src } = props.images[indexImage];
  if (loaded[src]) {
    setImageIndex(indexImage);
  } else {
    try {
      await loadImage(props.baseUrl + src);
      loaded[src] = true;
      setImageIndex(indexImage);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      // It could be network error
      // If it was not loaded. The image will be not display
    }
  }
};

onMounted(() => {
  setImage();
});

watch(() => props.signal, () => { setImage(); });
watch(() => props.images, () => {
  reset();
  setImage();
});

const image1 = computed(
  () => getImage(props.images, props.baseUrl, imageIndex1.value),
);
const image2 = computed(
  () => getImage(props.images, props.baseUrl, imageIndex2.value),
);
const showImage1 = computed(() => currentDisplay.value === CurrentDisplayEnum.image1);
</script>
