<script setup lang="ts">
import { TotalPurchasedBaseDTO } from './useTotalPoliciesLast24hrs';
import ProtectedToday from './ProtectedToday.vue';
import ProtectedTodayRules from './ProtectedTodayRules.vue';

defineProps<{
  schoolId: number,
  shortName?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  class: any,
}>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const data = (window as any)?.serverData?.last24hrs as TotalPurchasedBaseDTO;
</script>

<template>
  <ProtectedTodayRules v-slot="{ total }" :data="data">
    <ProtectedToday
      :school-id="schoolId"
      :short-name="shortName"
      :total="total"
      :class="$props.class"
    />
  </ProtectedTodayRules>
</template>
