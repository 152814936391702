import { createApp } from 'vue';
import 'polyfill-object.fromentries';
import 'array-flat-polyfill';

import addVueQuery from './global/vueQuery';
import { i18n } from './i18n/i18n';
import functions from './plugin/functions/templateFunctions';
import addBugsnag from './global/bugsnag/bugsnag';
import Wrapper from './components/base/wrapper.vue';
import SearchSchool from './components/navigation/search-school/search-school.vue';
import ModalMenu from './components/navigation/menu/modal-menu.vue';
import AgencyHours from './components/support/Agency/AgencyHours.vue';
import SchoolsGrid from './components/schools/schools-grid.vue';
import Referral from './components/referral/referral.vue';
import Contact from './components/contact/contact.vue';
import Licensing from './components/licensing/licensing.vue';
import ProfileMenu from './components/navigation/menu/profile-menu.vue';
import RentersCalculator from './components/renters/calculator/renters-calculator.vue';
import AnnouncementBanner from './components/banners/AnnouncementBanner.vue';
import CompanyPhotoCarousel from '@/components/careers/CompanyPhotoCarousel.vue';
import EmployeeBlurbCarousel from '@/components/careers/EmployeeBlurbCarousel.vue';
import ProtectedTodayPhp from '@/components/ProtectedToday/ProtectedTodayPhp.vue';
import Faq from '@/components/support/Faq/Faq.vue';
import LogoSlider from '@/components/slider/LogoSlider.vue';
import PrivacyPolicyAcknowledgement from '@/components/navigation/privacyPolicy/PrivacyPolicyAcknowledgement.vue';
import CookieAcknowledgement from '@/components/navigation/cookie/CookieAcknowledgement.vue';
import HomeTestimonial from '@/components/home/HomeTestimonial.vue';

const app = createApp({});
addBugsnag(app);
addVueQuery(app);
app.use(i18n);
app.mixin(functions);

app.component(Wrapper.name as string, Wrapper);
app.component(ProfileMenu.name as string, ProfileMenu);
app.component(SearchSchool.name as string, SearchSchool);
app.component(ModalMenu.name as string, ModalMenu);
app.component(AgencyHours.name as string, AgencyHours);
app.component(SchoolsGrid.name as string, SchoolsGrid);
app.component(Referral.name as string, Referral);
app.component(Contact.name as string, Contact);
app.component(Licensing.name as string, Licensing);
app.component(RentersCalculator.name as string, RentersCalculator);
app.component('AnnouncementBanner', AnnouncementBanner);
app.component('CompanyPhotoCarousel', CompanyPhotoCarousel);
app.component('EmployeeBlurbCarousel', EmployeeBlurbCarousel);
app.component('LogoSlider', LogoSlider);
app.component('Faq', Faq);
app.component('CookieAcknowledgement', CookieAcknowledgement);
app.component('PrivacyPolicyAcknowledgement', PrivacyPolicyAcknowledgement);
app.component('HomeTestimonial', HomeTestimonial);
app.component('ProtectedTodayPhp', ProtectedTodayPhp);
app.mount('#app');

/**
 * Navigation scroll handling
 */
document.addEventListener('DOMContentLoaded', () => {
  const navbar = document.getElementById('navbar');
  if (navbar) {
    let hasScrolled = false;
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 5) {
        if (!hasScrolled) {
          navbar.classList.add('nav-scrolled');
          hasScrolled = true;
        }
      } else {
        navbar.classList.remove('nav-scrolled');
        hasScrolled = false;
      }
    });
  }
});
