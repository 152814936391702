<template>
  <button
    type="button"
    :class="[
      'flex items-center w-full px-3 border leading-5 rounded-md h-10',
      'space-x-2 text-primary-lighter border-primary-lighter',
      'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
      'sm:text-sm',
    ]"
    @click="openModal"
  >
    <i class="fas fa-search " aria-hidden="true" />
    <div v-if="logo && imageLoaded">
      <img
        :src="logo"
        class="max-h-5 max-w-full"
        :alt="name"
        onload=""
      >
    </div>
    <div v-else class="line-clamp-2 w-30 text-xs text-primary-lighter text-left">
      {{ name }}
    </div>
  </button>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { string, func } from 'vue-types';

export default defineComponent({
  name: 'SearchSchoolButtonInternal',
  props: {
    logo: string(),
    name: string().isRequired,
    openModal: func<() => void>().isRequired,
  },
  setup(properties) {
    const src = computed(() => properties.logo);
    const imageLoaded = ref(false);
    const image = new Image();
    if (src.value) {
      image.src = src.value;
      image.onload = () => {
        imageLoaded.value = true;
      };
    }
    return {
      imageLoaded,
    };
  },
});
</script>
