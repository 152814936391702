import Bugsnag from '@bugsnag/js';

export default class BrowserStorage<Values> {
  private readonly key: string;

  private readonly defaultValues: Values;

  constructor(
    key: string,
    defaultValues: Values,
    prefix?: string,
  ) {
    this.key = `${prefix}${key}`;
    this.defaultValues = defaultValues;
  }

  get(): Values {
    const values = localStorage.getItem(this.key);
    if (typeof this.defaultValues === 'string') {
      return (values ?? this.defaultValues) as unknown as Values;
    }
    return this.getJson(values);
  }

  private getJson(values: string | null) {
    try {
      return values ? JSON.parse(values) : this.defaultValues;
    } catch (error) {
      return this.defaultValues;
    }
  }

  set(values: Values) {
    try {
      const item = (typeof values === 'string')
        ? values
        : JSON.stringify(values);
      localStorage.setItem(this.key, item);
    } catch (error) {
      Bugsnag.notify(error as Error);
    }
  }

  del() {
    localStorage.removeItem(this.key);
  }
}
