<template>
  <button
    id="rentersCalculatorButton"
    :class="$props.class"
    type="button"
    @click.prevent="openModal"
  >
    {{ text }}
  </button>
  <modal
    :show="show"
    :close="closeModal"
    :show-close-button="true"
    class="w-screen-md"
  >
    <h1 class="text-center font-semibold text-lg mb-5">
      How much coverage do I need?
    </h1>
    <p class="mx-auto text-center mb-10 max-w-lg">
      Input the estimated value of your belongings and
      GradGuard will help you figure out how much coverage you need!
    </p>
    <RentersCalculatorForm :quote-url="quoteUrl" />
  </modal>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { string, bool } from 'vue-types';

import modal from '../../../ui/modal/modal.vue';
import RentersCalculatorForm from './renters-calculator-form.vue';

export default defineComponent({
  name: 'RentersCalculator',
  components: {
    modal,
    RentersCalculatorForm,
  },

  props: {
    class: string().def('max-w-screen-md mt-24'),
    text: string().isRequired,
    quoteUrl: string().isRequired,
    open: bool().def(false),
  },

  setup(props) {
    const show = ref(props.open);
    return {
      show,
    };
  },

  methods: {
    closeModal() {
      this.show = false;
    },
    openModal() {
      this.show = true;
    },
  },
});
</script>
