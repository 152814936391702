<template>
  <faq-wrapper
    :has-search-results="hasSearchResults"
    :sections="sections"
    :has-content="hasContentBoolean"
  >
    <faq-search class="mt-10 px-4" @search-results="handleSearchResults" />
    <div v-if="showDefault && !hasContentBoolean" class="mt-10">
      <faq-default />
    </div>
    <div v-if="!hasSearchResults && hasContentBoolean && !hasSearched">
      <slot />
    </div>
  </faq-wrapper>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import FaqDefault from './FaqDefault.vue';
import FaqWrapper from './FaqWrapper.vue';
import FaqSearch from './FaqSearch.vue';

interface Props {
  hasContent: string;
}

const { hasContent } = defineProps<Props>();
const hasContentBoolean = computed(() => hasContent === 'true');
const sections = ref(['general', 'renters', 'tuition']);
const hasSearchResults = ref<boolean | undefined>(false);
const showDefault = computed(() => !hasSearchResults.value);
const hasSearched = ref<boolean>(false);

const handleSearchResults = (results: boolean | undefined, searched:boolean) => {
  hasSearchResults.value = results;
  hasSearched.value = searched;
};
</script>
