<template>
  <ul :class="$props.class">
    <li>
      <a
        :class="classItem"
        :href="accountUrl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fas fa-graduation-cap w-5" />
        <span>
          My Policies
        </span>
      </a>
    </li>
    <li>
      <a
        :class="classItem"
        :href="`${accountUrl}/account`"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fas fa-user-alt w-5" />
        <span>
          Settings
        </span>
      </a>
    </li>
    <li>
      <a
        :class="classItem"
        :href="`${accountUrl}/web/session/logout?redirect=${redirectLogoutUrl}`"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fas fa-sign-out-alt w-5" />
        <span>
          Logout
        </span>
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { string } from 'vue-types';

export default defineComponent({
  props: {
    class: string(),
    classItem: string(),
    accountUrl: string().isRequired,
    redirectLogoutUrl: string().isRequired,
  },
});
</script>
