<template>
  <TransitionGrid
    :images="images"
    :total-groups="totalGroups"
    :interval="6000"
    :bg-color="bgColor"
    class="grid grid-cols-2 md:grid-cols-4 gap-0.5 mt-6 lg:mt-8"
  />
</template>

<script lang="ts">
import { defineComponent, onUnmounted, ref } from 'vue';
import { number, string } from 'vue-types';

import TransitionGrid from '../../ui/transition-images/transition-grid.vue';
import { tuitionLogos, rentersLogos } from './schoolsImages';

/**
 * Number of logos:
 * 20 at lg breakpoint
 * 16 at md breakpoint
 * 10 at <= sm breakpoint
 */
const getTotalGroups = (numberOfLogos = 20) => {
  if (window.innerWidth >= 1024) {
    return Math.min(numberOfLogos, 20);
  }
  if (window.innerWidth >= 768) {
    return Math.min(numberOfLogos, 16);
  }
  return Math.min(numberOfLogos, 10);
};
// It could be a problem if renters and tuitions have same data --> fix it
const getImages = (product?: string) => {
  switch (product) {
    case 'renters': return rentersLogos;
    case 'tuition': return tuitionLogos;
    default: return [...tuitionLogos, ...rentersLogos];
  }
};

export default defineComponent({
  name: 'SchoolsGrid',
  components: {
    TransitionGrid,
  },

  props: {
    product: string(),
    numberOfLogos: number(),
    bgColor: string(),
  },

  setup(properties) {
    const images = getImages(properties.product);
    // const totalGroups = ref(getTotalGroups());
    const totalGroups = ref(getTotalGroups(properties.numberOfLogos));
    const getInnerWidth = () => {
      totalGroups.value = getTotalGroups(properties.numberOfLogos);
    };

    // Read the windows size
    window.addEventListener('resize', getInnerWidth);
    onUnmounted(() => {
      window.removeEventListener('resize', getInnerWidth);
    });

    return {
      images,
      totalGroups,
    };
  },
});
</script>
