import { onUnmounted, reactive, Ref } from 'vue';
import { useQuery, UseQueryOptions } from '@tanstack/vue-query';
import type { QueryKey } from '@tanstack/query-core';
import { ApiResponse, useGet } from 'use-api-vue';

import { ServerErrors } from '@/plugin/ajax/ApiResponse';

export interface Article {
  id: number;
  title: string;
}

interface ArticlesResponse {
  query: string;
  articles: Article[];
}

export interface SearchProps {
  query: string;
}

type BaseResponse = ArticlesResponse;

const useArticles = (
  props: Ref<SearchProps>,
  options?: Omit<UseQueryOptions<ApiResponse<BaseResponse>, ServerErrors, ApiResponse<BaseResponse>, QueryKey>, 'queryKey' | 'queryFn'>,
) => {
  const get = useGet({ onCancelCallback: onUnmounted });
  const url = '/support/faq';

  return useQuery<ApiResponse<BaseResponse>, ServerErrors>({
    queryKey: reactive([url, props]),
    queryFn: async ({ queryKey }) => {
      const input = queryKey[1] as { query: string };
      return get<BaseResponse>(url, input);
    },
    ...options,
  });
};

export default useArticles;
